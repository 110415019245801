module.exports = {
  actions: {
    delete: "Supprimer",
    cancel: "Annuller",
    save: "Enregistrer",
    edit: "Éditer",
    edit_options: "Modifier les options de visibilité",
    share: "Partager",
    update: "Mettre à jour",
    upload: "Téléverser",
    yes_remove: "Oui, supprimer",
    yes: "Oui",
    no: "Non",
    not: "Pas",
    ok: "D'accord",
    add: "Ajouter",
    create: "Créer",
    transfer: "Transfert",
    close: "proche",
    send: "Envoyer",
    open_in_recordings: "Voir dans les enregistrements",
    filter_by: "Filtrer par",
    filters: "Filtres",
    play: "Play",
    pause: "Pause",
    resume: "Reprendre",
    download: "Télécharger",
    fullscreen: "Plein écran",
    undo: "Annuler",
    redo: "Rétablir",
    blur: "Flouter",
    crop: "Couper",
    previous: "Précédent",
    next: "Suivant",
    collapse: "s'effondrer",
    back: "Retour",
    copy_to_clipboard: "Copier dans le presse-papier",
    resend_invite: "Renvoyer l'invitation",
    delete_invite: "Supprimer l'invitation",
    apply_filters: "Appliquer les filtres",
    go_to_media_hub: "Aller aux Media Hub",
    signin_with_google: "Se connecter avec Google",
    signup_with_google: "Inscrivez-vous avec google",
    signin_with_microsoft: "Se connecter avec Microsoft",
    signup_with_microsoft: "Inscrivez-vous avec Microsoft",
    signin_with_keycloak: "Sign in with Company SSO",
    signup_with_keycloak: "Sign up with Company SSO",
    skip: "Passer",
    export_as: "Exporter en",
    configure: "Configurer",
    compare: "Comparaison",
    compare_disabled: "Comparaison désactivée lorsque le joueur est actif",
    apply: "Appliquer",
  },
  title: {
    long_press:
      "S'il vous plaît, appuyez longuement sur l'image pour enregistrer",
    long_press_to_open: "appuyez longuement pour ouvrir",
    long_press_ios_external_title:
      "Veuillez appuyer longuement sur ce lien pour l'ouvrir",
    long_press_ios_mp4_title:
      "iOS n'autorise pas le téléchargement de fichiers MP4",
    long_press_mp4_text1:
      "Pour télécharger ce fichier, nous devons ouvrir ce lien sur un navigateur externe.",
    long_press_mp4_text2:
      "Veuillez appuyer longuement sur ce lien pour ouvrir le clip sur un navigateur externe et réessayer de télécharger le clip.",
    first_snapshot: "Premier",
    last_snapshot: "Dernier",
    required_fields_description: "Indique les champs obligatoires",
    no_images_for_period: "Il n'y a pas d'images pour la période sélectionnée",
    seconds: "Secondes",
    snapshots: "Images",
    duration: "Durée",
    clip_type: "Type de clip",
    fps: "IPS",
    share_media: "Partager le média",
    delete_media: "Supprimer le média",
    delete_sharer: "Supprimer Share",
    delete_preset: "Supprimer Préréglage",
    delete_account: "Supprimer votre compte",
    delete_comment: "Supprimer le commentaire",
    archive_comment: "Archiver le commentaire",
    unarchive_comment: "Désarchiver le commentaire",
    create_comment: "Ajouter un commentaire",
    create_clip: "Créer un clip",
    recording_schedule: "Calendrier d'enregistrement",
    create_local_recording_clip: "Créer un clip d'enregistrement local",
    media_url: "URL moyenne",
    upload_file: "Téléverser un fichier",
    save_to_gallery: "Sauvegarder dans la gallerie",
    visibility_options: "Options de visibilité",
    sharing_camera: "Partage de caméra",
    export_as: "Exporter en tant que...",
    export: "Exporter",
    create_timelapse: "Créer Time-Lapse",
    transfer_camera_ownership: "Transférer la propriété de la caméra",
    presets: "Préconfigurations",
    create_a_preset: "Ajouter un préréglage",
    send_to: "Envoyer à",
    active_projects: "Projets actifs",
    completed_projects: "Projets terminés",
    export_xray: "Exporter Xray",
  },
  sections: {
    projects: "Projets",
    cameras: "Caméras",
    BIMView: "BIM",
    droneView: "Drone",
    _360View: "360°",
    site_planner: "Site Planner",
    automations: "Automatisations",
    media_hub: "Media Hub",
    video_wall: "Gallerie",
    live_chat: "Messagerie instantanée",
    tutorials: "Tutoriels",
    settings: "Paramètres",
    sign_in: "Se connecter",
    sign_out: "Se déconnecter",
    gate_report: "Rapport de portail",
    sign_up: "S'inscrire",
    about: "À propos d'Evercam",
    blog: "Blog",
    company: "compagnie",
    feedback: "Retour d'information",
    user_manual: "Manuel de l'Utilisateur",
    updates: "Mises à jour",
    timeline: "Chronologie",
    copilot: "Copilot",
  },
  pages: {
    timeline: "Chronologie",
    copilot: "Copilot",
    live_view: "Vue en direct",
    recordings: "Enregistrements",
    edge_video: "Enregistrements Edge",
    sharing: "Partage",
    media_hub: "Media Hub",
    compare: "Comparateur",
    gate_report: "Rapport d'engins",
    bim_compare: "Comparateur BIM",
    site_planner: "Site Planner",
    account: "Mon Compte",
    activities: "Activités",
    live_view_widget: "Widget de vue en direct",
    recordings_widget: "Widget d'enregistrements",
    bim_compare__widget: "Widget de BIM Comparateur",
    connectors: "Connecteurs",
    tutorials: "Tutoriels",
    timelapse: "Time-Lapse",
    sign_up: "s'inscrire",
    progress_photos: "Photos de progression",
  },
  forms: {
    name: "Nom",
    author: "Crée par",
    camera: "Caméra",
    title: "Titre",
    sort_by: "Trier par",
    filters: "Filtres",
    from_date: "Date de début",
    to_date: "Date de fin",
    email: "Email",
    first_name: "Prénom",
    last_name: "Nom",
    language: "Langue",
    phone_international: "Numéro de téléphone",
    dial_code: "Préfixe de téléphone",
    country: "Pays",
    unit_system: {
      label: "Système d'unité",
      items: {
        metric: "Métrique",
        imperial: "Impérial",
      },
      meters: "Mètres",
      feet_and_inches: "Pieds et pouces",
    },
    password: "Mot de passe",
    persona: {
      label: "Comment comptez-vous utiliser Evercam?",
      items: {
        hq: "Aperçu de projets (e.g. Investisseur / Siège social)",
        pm: "Gestion de projet (Sur terrain)",
        qs: "Prévention de litiges & Administration de contrats (e.g. Géomètre)",
        marketing: "Marketing",
        hs: "Examen d'incidents & Amélioration continue (e.g. Santé et sécurité)",
        di: "Numérique / Innovation",
        other: "Autre",
      },
    },
    optional: "optionel",
  },
  content: {
    update_failed: "Échec de la mise à jour de {resource}",
    update_success: "{resource} mis à jour avec succès",
    company: {
      fetch_cameras_failed:
        "Échec de la récupération des caméras de l'entreprise !",
      fetch_projects_failed:
        "Échec de la récupération des projets de l'entreprise !",
      fetch_company_details_failed:
        "Échec de la récupération des détails de l'entreprise !",
    },
    etimeline: {
      labels: {
        anpr: "ANPR ",
        bim: "BIM Comparateur ",
        exNvrRecordings: "ExNvr vidéo",
        safetyReports: "Rapports de sureté",
        constructionReports: "Rapports de construction",
        siteActivity_workers: "Activité des travailleurs",
        siteActivity_equipment: "Activité de l'équipement",
        siteActivity_vehicles: "Activité des véhicules",
        comments: "Commentaires",
      },
      snapshot_not_found: "Aucun instantané trouvé sur cette horodatage",
    },
    player_mode: {
      video: "Video",
      jpeg: "JPEGs",
    },
    snapshot_load_failed: "Échec du chargement de l'instantané !",
    beta_feature_text:
      "Cette fonctionnalité est en version bêta. Nous aimerions recevoir des commentaires à",
    embed_code: "Intégrer ce code sur votre page Web",
    public_camera: "caméra publique",
    custom_refresh_rate_text:
      "Nombre de secondes à attendre avant le prochain rafraîchissement de l'image",
    live: "DIRECT",
    pending: "...en cours",
    you: "vous",
    email_verified: "E-mail vérifié avec succès.",
    show_thumbnails: "Afficher la miniature",
    copy_shareable_link: "Copier le lien de la caméra",
    copy_link: "Copier le lien",
    copy_success: "Copié dans le presse-papier",
    copy_error: "La copie a échoué",
    cloud_recording: "Enregistrement en nuage",
    cloud_recording_tooltip_text:
      "Un clip d'enregistrement dans le cloud prend des images (1 jpeg/10 secondes) du cloud et les compresse en une vidéo. Par exemple, une vidéo d'une heure contiendra 360 images compressées en 15 minutes environ",
    local_recording_tooltip_text:
      "Un clip d'enregistrement local prend la séquence vidéo (8 FPS) de l'appareil, par exemple, une vidéo d'une heure durera 1 heure",
    page_not_found_error:
      "La page que vous recherchez n'existe plus ou vous n'y avez pas accès. Pour obtenir de l'aide,",
    requested_by: "Demandé par",
    share_request_revoke: "Rappel de demande de partage révoqué",
    share_request_revoke_text:
      "Le rappel de demande de partage a été révoqué. Vous ne recevrez pas d'e-mails de rappel d'Evercam sur cette demande de partage.",
    good_bye_quote:
      "Et ne craignez pas que l'existence fermant votre Compte, et le mien, n'en sache plus la même chose ; L'Eternal Saki de ce bol a versé des millions de bulles comme nous, et se déversera.",
    calendar: "Calendrier",
    pre_authenticate: "Pré-authentification",
    delay_recordings: "Restreindre les images récentes",
    delay_recordings_by: "Décaler de",
    timeline: "Chronologie",
    dark_mode: "mode sombre",
    camera_status: {
      offline:
        "Cette caméra est en cours de maintenance. Nous sommes entrain de la resoudre.",
      under_maintenance:
        "Cette caméra est en cours de maintenance. Nous sommes entrain de la resoudre.",
      waiting_for_site_visit:
        "Cette caméra est en cours de maintenance. Nous sommes entrain de la resoudre.",
      waiting: "En attente d'activation",
      decommissioned: "Désaffectée",
      on_hold:
        "L'affichage en direct et les enregistrements dans le cloud sont suspendus pour cette caméra. Si vous avez des questions, veuillez contacter votre gestionnaire de compte",
    },
    camera_statuses: {
      online: "En ligne",
      offline: "Hors ligne",
      under_maintenance: "En maintenance",
      waiting: "En attente d'activation",
      decommissioned: "Désaffectée",
      on_hold: "En attente",
    },
    team_members: {
      title: "membres de l'équipe",
      add_member: "Ajouter un membre",
    },
    project_members: {
      title: "Membres",
    },
    camera_discoverability: {
      is_public_short: "Publique",
      is_discoverable_short: "Découvrable",
      is_private_short: "Privée",
    },
    recordings: {
      events: "Évènements",
      playback_speed: "Vitesse de lecture",
      xray: "Rayons X",
      brain: "Cerveau",
      xray_disabled: "Rayons X désactivés lorsque le lecteur est activé",
      brain_tool_disabled_on_live:
        "Reconnaissance désactivée sur le flux en direct et lecture automatique",
      brain_tool_disabled_no_snapshot:
        "Reconnaissance désactivée, aucun instantané disponible",
      brain_tool_processing:
        "Analyse en cours... Veuillez patienter s'il vous plait, cela peut durer jusqu'à 20 secondes.",
      brain_tool_error: "L'analyse a échoué. Veuillez réessayer ultérieurement",
      brain_tool_no_results: "Pas de résultats pour la photo selectionnée.",
      calendar: "Calendrier",
      clarity_text: `
        <p class="caption mb-2"><strong>Enregistrements Evercam</strong></p>
          <p class="caption mb-1">
            Cet outil est destiné à l'examen des conditions du site en 1 heure
            incréments à 6 images par seconde.
          </p>
          <p class="caption mb-1">
            La plupart des clients Evercam utilisent cet outil pour les incidents et la sécurité
            Commentaires.
          </p>
          <p class="caption mb-0">
            Vous pouvez contacter
            <a target="self" href="mailto:support@evercam.io"
              >support@evercam.io</a
            >
            pour une vidéo entièrement fidèle à votre projet.
          </p>
      `,
      comments: "Commentaires",
    },
    activities: {
      logs_load_error:
        "Échec du chargement des journaux d'activité, en raison d'une erreur",
    },
    sharing: {
      read_only_message: `
        <p class="caption">
          Votre accés à cette <strong>caméra privée</strong>
          est en mode <strong>Lecture seule</strong>.
        </p>
        <p class="caption">
          Afin de partager une caméra privée avec d'autres utilisateurs,
          vous devez avoir un accés <storng>Pleins droits</storng>
          Sinon la caméra doit etre <strong>Publique</strong>
        </p>
        <p class="caption">
          Si vous voulez partager cette caméra,
          veuillez demander un accés <strong>Pleins droits</strong> 
          <strong>Construction Evercam</strong> par email, à l'adresse: construction@evercam.io,
          sinon en discussion sur la section Messagerie Instantanée.
        </p>
        <p class="caption">
          Avec Evercam, il est possilbe de partager votre caméra
          avec autant d'utilisateurs que vous souhaitez.
        </p>
        <p class="caption">Allez-y, partagez l'amour !</p>
      `,
    },
    photo_progress: {
      all: "Tout",
      new: "Nouvelle photo de progression",
      edit: "Modifier la photo de progression",
      create: "Créer une photo de progression",
      delete: "Supprimer la photo de progression",
      create_success: "Photo de progression créée avec succès",
      create_failed: "Échec de la création de la photo de progression",
      delete_success: "Photo de progression supprimée avec succès",
      delete_failed: "Échec de la suppression de la photo de progression",
      update_success: "Photo de progression mise à jour avec succès",
      update_failed: "Échec de la mise à jour de la photo de progression",
      resume_success: "Photo de progression reprise avec succès",
      pause_success: "Photo de progression en pause avec succès",
      update_status_failed: "Échec de la mise à jour du statut de pause",
      delete_confirmation:
        "Êtes-vous sûr de vouloir supprimer cette photo de progression ?",
      no_item: {
        heading_text:
          "La photo de progression est un email planifié contenant un instantané de votre caméra. Incluez autant d'emails et de caméras que vous le souhaitez.",
        subheading_text1:
          "Cette fonctionnalité astucieuse vous envoie des images haute résolution à jour directement dans votre boîte de réception.",
        subheading_text2:
          "Envoyez des images la veille d'une réunion sur site ou envoyez à tous les parties prenantes du projet une mise à jour quotidienne/hebdomadaire.",
        subheading_text3:
          "Maintenant, tout le monde est à jour, et vous n'avez pas quitté votre bureau.",
      },
    },
    or_use: "Ou utiliser",
    back_to_projects: "Revenir aux projets",
    no_search_result: "Aucun projet ne correspond à votre recherche.",
    no_search_result_for: "Aucun resultat pour",
    no_project_sidebar: "Aucun projet.",
    no_user_search_result: "Aucun utilisateur trouvé.",
    no_responding_server: "Le serveur ne répond pas. Nous y travaillons.",
    server_reconnecting_message:
      "Cette page vérifiera automatiquement l'état du serveur et tentera de se reconnecter dans",
    something_wrong_text:
      "Quelque chose s'est mal passé. Veuillez contacter l'assistanceQuelque chose s'est mal passé. Veuillez réessayer et, si le problème persiste, contactez le support",
    invalid_snapshot_timestamp_text:
      "Horodatage de l'instantané non valide, veuillez réessayer.",
    further_assistance: "veuillez contacter l'assistance au",
    no_project_text:
      "Vous n'avez pas de projet dans votre compte. Pour obtenir de l'aide,",
    no_site_plans_text: "Il n'y a actuellement aucun 'site plan' à afficher.",
    no_camera_text:
      "La caméra que vous recherchez n'existe plus ou vous n'y avez pas accès.",
    no_project_exist_text:
      "Le Projet que vous recherchez n'existe plus ou vous n'y avez pas accès. Pour obtenir de l'aide,",
    no_connection_text:
      "Il semble que vous n'êtes pas connecté à Internet. S'il vous plait, vérifiez votre connexion internet.",
    start_time: "Heure de début",
    start_date: "Date de début",
    select_timezone: "Sélectionnez le fuseau horaire",
    email_addresses: "Adresses email",
    gate_report: {
      camera: "Caméra",
      project_has_no_gate_report: "Le projet n'a pas de rapport de portail",
      arrived_at: "Entrée",
      left_at: "Sortie",
      unknown: "Inconnu",
      vehicle_type: "Type de véhicule",
      plate_number: "Plaque d'immatriculation",
      plate_preview: "Aperçu du numéro de plaque",
      category: "Catégorie",
      duration: "Duration",
      thumbnail: "Aperçu",
      thumbnail_context: "Contexte",
      event_time: "Heure de l'évènement",
      anpr_data: "Données ANPR",
      event_type: "Direction",
      export: {
        download: "Télécharger",
        download_failed: "Échec du téléchargement",
      },
      unprocessed: "Non traité",
      gates: "Entrées",
      count_in: "entrées",
      count_out: "sorties",
      count_unknown: "n/a",
      filters: {
        all_cameras: "Toutes les cameras",
        all_vehicles: "Tous les véhicules",
        all_events: "Tous les evenements",
        last_week: "La semaine dernière",
        last_month: "Le mois dernier",
        overall: "Tout",
      },
      charts: {
        deliveries_by_truck_type: "Livraisons par type de véhicule",
        total_deliveries_by_truck_type:
          "Total des livraisons par type de véhicule",
      },
    },
    anpr: {
      directions: {
        all: "Tous",
        forward: "Avant",
        reverse: "Arrière",
        unknown: "n/a",
      },
    },
    generic_error_message:
      "Une erreur s'est produite. Veuillez réessayer à nouveau. Si le problème persiste, veuillez contacter le support technique",
    lazy_load_error_message:
      "Échec du chargement, veuillez actualiser la page, si le problème persiste, contactez le support.",
    loading: "Chargement",
    thumbnail: "Aperçu",
    events: { in: "arrivée", out: "départ" },
    line: "Ligne",
    brush: "Pinceau",
    rectangle: "Rectangle",
    ellipse: "Ellipse",
    arrow: "Flèche",
    text: "Texte",
    filters: "Filtres",
    more: "Plus",
    less: "Moins",
    status: "Statut",
    since: "Depuis",
    timelapse: {
      no_snapshot_found: "Aucun instantané trouvé sur cette période",
      period: "Période",
      schedule: "Programme",
      "24_hours": "24 dernieres heures",
      "7_days": "7 derniers jours",
      "30_days": "30 derniers jours",
      whole_project: "Tout le projet",
      continuous: "Continu",
      timelapse_inprogress: "Demande de Time-Lapse en cours.",
      timelapse_wait_time_text:
        "Cela peut prendre environ 10 minutes. Veuillez revenir plus tard.",
      working_hours: "Heures d'ouverture",
      working_hours_description: "(Lun to Ven - 8am to 6pm)",
      note: `<p class="caption mb-0">
      Cette vidéo est produite par Time-Lapse Creator d'Evercam. Pour des vidéos professionnelles, veuillez contacter l'équipe vidéo à <a href="mailto:video@evercam.io">video@evercam.io</a> ou remplissez ce <a href="https://forms.zohopublic.com/Evercam/form/TimelapseFormV2/formperma/1Z5PiEzmFCLDG9K79mI0mAXxyaX8app_0QxuQer0MU4" target="_blank">formulaire</a>
    </p>`,
      clarity_text: `
        <p class="caption mb-2"><strong>Créateur de Time-Lapse Evercam</strong></p>
          <p class="caption mb-1">
            Cette vidéo est produite par Time-Lapse Creator d'Evercam. Pour des vidéos professionnelles, veuillez contacter l'équipe vidéo à <a href="mailto:video@evercam.io">video@evercam.io</a> ou remplissez ce <a href="https://forms.zohopublic.com/Evercam/form/TimelapseFormV2/formperma/1Z5PiEzmFCLDG9K79mI0mAXxyaX8app_0QxuQer0MU4?referrername={project}" target="_blank">formulaire</a>
          </p>
          <p class="mb-1"><strong>__________</strong></p>
          <p class="caption mb-1">
            Cet outil est destiné à l'examen des conditions du site sur de plus longues durées.
          </p>
          <p class="caption mb-1">
            La plupart des clients Evercam utilisent cet outil pour communiquer les progrès et les jalons du site.
          </p>
          <p class="caption">
            Vous pouvez contacter
            <a target="self" href="mailto:support@evercam.io"
              >support@evercam.io</a
            >
            pour une vidéo entièrement fidèle à votre projet.
          </p>
      `,
      custom: "Personnalisé",
      custom_period: "Personnalisé",
      "30_seconds": "30 Secondes",
      "60_seconds": "60 Secondes",
      "90_seconds": "90 Secondes",
      from: "De:",
      to: "À:",
      remove_timestamp: "Supprimer l'horodatage",
      smooth_transition: "Transition de cadre en douceur",
      logo: "Logo",
      success_message:
        "Votre Time-Lapse est en cours de génération et sera disponible dans le dossier Media Hub dans les 10 prochaines minutes.",
      creation_success: "Votre Time-Lapse crée avec succès",
      help_title: "Time-Lapse",
      help_description_1:
        "Partagez instantanément ce qui se passe sur votre chantier de construction, développez votre propre vidéo Time-Lapse en quelques clics seulement.",
      help_description_2:
        "Sélectionnez la période sur laquelle vous souhaitez vous concentrer à partir de n'importe quel moment votre projet.",
      help_description_3:
        "Nous vous proposons plusieurs options d'exportation afin que votre vidéo soit prête pour les réseaux sociaux, les communications internes.",
      help_description_4: "Vous souhaitez créer une vidéo personnalisée ?",
      help_description_5:
        "Pas de problème - notre équipe d'éditeurs vidéo est toujours là pour vous aider à donner vie à votre vision.",
      help_description_6: "Contactez-moi aujourd'hui:",
      help_email: "erika@evercam.io",
      format_step: "Format",
      effects_step: "Effets",
      confirm_step: "Confirmation",
      error_step: "Erreur",
      finish_step: "Terminé",
      desc_remove_timestamp:
        "Cette option supprime la date et l'heure affichées en haut à gauche de votre vidéo",
      desc_smooth_transition:
        "Cette option rendra la transition entre les images de la video vidéo plus fluide et chaque image se fondra dans la suivante",
      desc_confirm:
        "Enfin, choisissez un nom pour votre Time-Lapse, puis cliquez sur Enregistrer, et la vidéo sera bientôt disponible dans votre section Media Hub",
    },
    invalid_field: "Valeur de champ invalide : {target}",
    fetch_resource_failed: "Impossible de récupérer {resource}",
    logo: {
      add_success: "Logo ajouté avec succès",
      add_error: "Échec du téléchargement de l'image en raison d'une erreur",
    },
    media_hub: {
      share_disable_tooltip: "Le média n'est pas public",
      upload_file: {
        drag_file: "Déposez votre fichier ici<br /> ou cliquez pour parcourir",
        drag_again:
          "Échec ! Cliquez ici ou déposez votre fichier<br /> pour réessayer",
        selected_file: "Fichier Sélectionné",
      },
      link_field_labels: {
        public_image: "Image Publique",
        clip_video: "Clip Vidéo",
        gif_file: "Fichier GIF",
        mp4_file: "Fichier MP4",
        timelapse_video: "Time-Lapse Vidéo",
      },
      save_to: "Enregistrer sur Hub Média",
      save_success: "Xray enregistrée sur Hub Média avec succès !",
      save_failed: "Échec de l'enregistrement de Xray sur Hub Média !",
      save_image_success: "Votre image a été enregistrée dans Media Hub",
      not_found: "Média introuvable ou supprimée",
      clip_not_found: "Média introuvable ou supprimé",
      create_failed: "Impossible de créer le clip",
      delete_media: "Êtes-vous sûr de vouloir supprimer ce média ?",
      make_public_success:
        "Votre Média est maintenant disponible publiquement.",
      make_public_error:
        "Une erreur s'est produite lors de la publication de votre Clip.",
      make_private_error:
        "Une erreur s'est produite lors de la privatisation de votre Clip.",
      make_private_success: "Votre Média est maintenant privée.",
      title_edit_success: "Titre modifié avec succès",
      title_edit_error: "On peut pas changer le titre",
      edit_success: "Le média modifiée avec succès",
      delete_success: "Le média a été supprimée avec succés",
      video_not_found: "Vidéo non trouvée",
      delete_error:
        "Une erreur est survenue lors de la suppréssion du clip. Veuillez contacter le support technique.",
      from: "De:",
      to: "À:",
      createdOn: "Crée le",
      createdBy: "Crée Par",
      timelapse_inprogress_text: "Demande de Time-Lapse en cours.",
      timelapse_wait_time_text:
        "Cela peut prendre environ 10 minutes. Veuillez revenir plus tard.",
      sort_by: {
        first_created: "Dernière création",
        last_created: "Première création",
        cameras_desc: "Caméra Z-A",
        cameras_asc: "Caméra A-Z",
        author_desc: "Créateur Z-A",
        author_asc: "Créateur A-Z",
        type: "Type",
      },
      media_type: {
        all: "Tout les Media",
        clip: "Cloud Recording Clip",
        local_clip: "Local Recording Clip",
        compare: "Evercam Compare",
        xray: "XRay",
        url: "URL (YouTube, Vimeo...)",
        file: "Upload File",
        edit: "Edit",
        timelapse: "Time-Lapse",
      },
      hints: {
        timelapse: "Cela ouvrira l'onglet Time-Lapse",
        compare: "Cela ouvrira l'onglet Comparer",
        default: "Cela ouvrira l'assistant de création d'un clip",
      },
      no_media: "Il n'y a encore rien ici.",
      gallery_info:
        "Cette zone affiche tous les médias (clips, vidéos, comparaisons, etc.) liés à votre appareil photo.",
      not_found_error: {
        not_found_content:
          "Vous n'avez pas accès au média que vous recherchez ou il n'est pas disponible. Vous serez redirigé vers ",
        non_authorized_content:
          "Le média que vous recherchez n'est pas public. Vous serez redirigé vers ",
        time_before: "dans ",
        time_after: "secondes.",
      },
    },
    weather: {
      title: "Météo",
      no_weather:
        "Désolé, nous n'avons aucune donnée disponible pour cette date.",
      precipitation: "Précipitation",
      humidity: "Humidité",
      wind: "Vent",
      temperature: "Température",
      data_source: "Données météorologiques fournies par",
      rain: "Pluie",
    },
    units: {
      inch: '"',
      foot: "'",
      metre: "m",
      metric: {
        temperature: "C",
        speed: "km/h",
        speed_ms: "m/s",
        rain: "mm",
      },
      imperial: {
        temperature: "F",
        speed: "mph",
        speed_ms: "mph",
        rain: '"',
      },
    },
    account: {
      delete_failed: "Impossible de supprimer votre compte",
      update_user_failed: "Impossible de mettre à jour l'utilisateur",
      update_password_failed: "Impossible de mettre à jour le mot de passe",
      manage_your_avatar: "Gérez votre avatar avec",
      personal_information: "Informations de compte",
      update_password: "Mettre à jour le mot de passe",
      theme: "Thème",
      reset_password: "Réinitialiser le mot de passe",
      reset_password_note:
        "Lorsque vous cliquez sur le bouton ci-dessous, un lien de réinitialisation du mot de passe vous sera envoyé par e-mail",
      reset_password_notification:
        "Vous recevrez un e-mail de réinitialisation du mot de passe. Pensez à vérifier vos spams",
      has_no_password_message:
        "Réinitialisez votre mot de passe pour accéder à Evercam ",
      current_password: "Mot de passe actuel",
      new_password: "nouveau mot de passe",
      confirm_password: "Confirmez le mot de passe",
      delete_account: "Supprimer le compte",
      delete_account_text:
        "En supprimant votre compte, vous perdrez toutes vos données",
      confirm_delete_account_text:
        "Les caméras listées ci-dessous seront supprimées le (vous pouvez transférer la propriété avant de supprimer votre compte.)",
      transfer_camera: "Transférer la caméra",
      type_delete_text:
        "Saisissez l'adresse e-mail de ce compte pour confirmer que vous souhaitez supprimer votre compte et toutes les caméras que vous possédez actuellement.",
      fetch_access_features_error:
        "Échec de récupération de l'accès aux fonctionnalités de l'utilisateur!",
    },
    sessions: {
      title: [
        "Ceci est une liste d'appareils qui se sont connectés à votre compte.",
        "toutes les sessions que vous ne reconnaissez pas.",
      ],
      revoke: "Révoquer",
      revoke_session: "Révoquer Session(s)",
      headers: {
        id: "Session ID",
        ip_address: "Adresse IP",
        createdAt: "Crée",
        browser: "Navigateur",
        os: "OS",
        lastUsedAt: "Dernière utilisation",
        actions: "",
        loginType: "Type de connexion",
      },
      load_sessions_error:
        "Le chargement des sessions actives a échoué, en raison d'une erreur",
      search: "Chercher",
      content_loading: "Veuillez patienter...",
      minimum_selection: "Sélectionnez au moins une session",
      revoke_confirm:
        "Voulez-vous vraiment révoquer les sessions sélectionnées?",
      sessions_deleted: "Session (s) révoquée avec succès.",
    },
    ptz: {
      zoom_in: "Zoom avant",
      zoom_out: "Zoom arrière",
      reset_position: "Réinitialiser la position",
      move_up: "Déplacer vers le haut",
      move_down: "Déplacer vers le bas",
      move_right: "Déplacer vers la droite",
      move_left: "Déplacer vers la gauche",
      presets: "Préréglages",
      delete_preset: "Êtes-vous sûr de vouloir supprimer le préréglage:",
      preset_applied_failed: "Le préréglage appliqué a échoué.",
      preset_created: "Préréglage créé avec succès.",
      preset_updated: "Préréglage mis à jour.",
      preset_deleted: "Préréglage supprimé avec succès..",
      preset_input: "Entrez le nom du préréglage",
      location_updated: "Demande envoyée",
      preset_delete_confirmation:
        "Êtes-vous sûr de vouloir supprimer le préréglage {presetName}?",
      ptz_box_not_allowed: "Vous n'avez pas le droit de déplacer la caméra",
    },
    live_view: {
      web_rtc_load_failed:
        "Échec du chargement de WebRtc, basculer vers le flux JPEG",
      inactive_live_view: "Live View suspendu en raison d'une inactivité.",
      pause_live_view: "Affichage en direct suspendu",
    },
    minutes: "Minutes",
    hours: "Heures",
    days: "Jours",
    model: "Modèle",
    visibility: "Visibilité",
    battery_levels: "Niveaux de batterie",
    installed_on: "Installé le",
    download_pending:
      "Le téléchargement est en cours, Cela peut prendre du temps en fonction du débit de la connexion.",
    compare: {
      before: "Avant",
      after: "Après",
      compare_saved:
        "Votre image de comparaison a été enregistrée dans les Media Hub.",
      overtime_warning:
        "Cela prend plus de temps que d'habitude, veuillez visiter la page du media hub après un certain temps ou contacter le support pour plus d'assistance",
      choose_title:
        "Choisissez votre titre de comparaison, puis cliquez sur Enregistrer, et il sera disponible dans la page des Media Hub.",
    },
    shares: {
      fetch_share_request_failed:
        "Échec de la récupération de la demande de partage par clé",
      transfer_camera_failed:
        "Impossible de transférer la propriété de la caméra",
      resend_failed: "Échec de la nouvelle demande de partage",
      update_rights_failed: "Impossible de mettre à jour les droits de partage",
      delete_share_success: "Supprimé avec succès",
      resend_share_request:
        "Un email de notification a été envoyé à l'adresse email spécifiée",
      rights_updated: "Droits mis à jour",
      switch_visibility_failed:
        "Impossible de changer la visibilité de la caméra",
      delete_share_failed: "Impossible de supprimer le partage de caméra",
      delete_share_request_failed:
        "Impossible de supprimer la demande de partage de caméra",
      delete_share: "Êtes-vous sûr de vouloir supprimer le partage de caméra ?",
      already_shared: "La caméra a déjà été partagée avec",
      share_success: "La caméra a été partagée avec succés avec",
      add_user: "Ajouter un utilisateur",
      visibility_options: {
        this_camera_is: "cette caméra est",
        public_discoverable: {
          label: "Publique sur le web",
        },
        public_undiscoverable: {
          label: "Toute personne disposant du lien peut y accéder",
        },
        private: {
          label:
            "Seules des personnes spécifiques peuvent y accéder (e-mails répertoriés)",
        },
      },
      transfer_camera_ownership: {
        description:
          "Transférez la propriété à un utilisateur avec qui vous partagez déjà la caméra.",
        note: "Une fois le transfert effectué, la caméra sera partagé avec vous avec tous les droits",
        input: {
          placeholder: "Sélectionnez un nouveau propriétaire...",
        },
      },
      create_form: {
        toggle_btn: "Partager la caméra",
        title: "Partager la caméra avec",
        rights_labels: {
          read_only: "Lecture seulement",
          read_only_share: "Lecture + Partage",
          full: "Tous les droits",
          none: "Aucun Droit",
          custom: "Droits personnalisés",
        },
        message_to: "Message à envoyer par email",
      },
      is_owner: "Propriétaire",
      is_restricted: "Restreint (Direct seulement)",
      headers: {
        shared_with: "Partagé avec",
        shared_by: "Partagé par",
        rights: "Droits",
        last_seen: "Dernière détection",
        action: "Action",
      },
    },
    bim: {
      no_bim_found: "Images BIM introuvables pour la caméra",
      transparency: "Transparency",
      no_bim_image: "Modèle BIM non disponible.",
      export_dialog: {
        bim_in_progress:
          "La comparaison BIM est en cours d'exportation et sera bientôt disponible dans les Media Hub (cela peut prendre quelques minutes).",
        bim_error: "Échec de l'exportation de la comparaison BIM",
        instruction_1: "Cela peut prendre jusqu'à 2 minutes",
        instruction_2:
          "Une fois terminé, des liens apparaîtront ici pour visualiser/télécharger.",
        instruction_3: "Cela apparaîtra également dans la page Media Hub.",
      },
    },
    drone: {
      imagery_hint: "Plans d'imagerie non disponibles pour ce projet",
      compare_hint:
        "Vous avez besoin d'au moins deux vols pour activer la fonction de comparaison",
      volume_hint: "Volume non disponible pour ce vol",
      inspection_hint: "Inspection non disponible pour ce vol",
      bim_hint: "Un BIM est nécessaire pour activer cette fonctionnalité",
    },
    _360: {
      compare_hint:
        "Vous avez besoin d'au moins deux visites guidées pour activer la fonction de comparaison",
      mini_model_hint: "Pas de modèle mini 3D disponible pour cet étage",
    },
    site_planner: {
      add_site_plan: "Ajouter un Site Plan",
      search_site_plans: "Chercher un plan par nom, id ou adresse",
    },
    connectors: {
      contact_message:
        "Nous voulons vous faciliter la vie en ajoutant des connecteurs avec vos outils préférés. Envoyez-nous un message pour nous dire ce que vous voulez.",
      contact_us: "Contactez nous",
      connect: "Connecter",
      connect_to_forge_viewer: "Connecter au visualisateur autodesk",
      remove: "Supprimer",
      upload_to_aconex_failed: "Impossible de téléverser des images sur Aconex",
      upload_to_procore_failed:
        "Impossible de téléverser des images sur Procore",
      upload_to_autodesk_failed:
        "Impossible de téléverser des images sur Autodesk",
      upload_to_aconex: "Téléchargez vos images sur Aconex",
      connect_to_aconex: "Connectez-vous à Aconex",
      aconex_add_success: "Connecteur Aconex ajouté avec succès",
      aconex_add_error: "Aconex connecteur échoué, Veuillez réessayer.",
      aconex_remove_success: "Connecteur Aconex supprimé avec succès",
      voyage_control_remove_success:
        "Le connecteur VoyageControl a été supprimé avec succès",
      aconex_remove_error: "Échec de la demande de révocation",
      select_aconex_project: "Sélectionnez le projet Aconex",
      upload_to_procore: "Téléchargez vos images sur Procore",
      select_provider: "Sélectionnez le fournisseur",
      select_procore_project: "Sélectionnez le projet Procore",
      select_procore_album: "Aucun projet n'a été sélectionné",
      no_project_selected: "No project was selected",
      procore_remove_success: "Connecteur Procore supprimé avec succès",
      autodesk_remove_success: "Connecteur Autodesk supprimé avec succès",
      select_autodesk_hub: "Sélectionnez le hub Autodesk",
      select_autodesk_project: "Sélectionnez le projet Autodesk",
      select_autodesk_folder: "Sélectionnez le dossier Autodesk",
      revoke_procore_failed: "Échec de la demande de révocation",
      revoke_autodesk_failed: "Échec de la demande de révocation autodesk",
      revoke_voyage_control_failed:
        "La demande de révocation de VoyageControl a échoué",
      connect_with_aconex: "Connectez-vous avec aconex",
      connect_with_autodesk: "Connectez-vous avec autodesk",
      connect_with_aconex_text:
        "Pour le moment, vous n'êtes pas connecté à Aconex, si vous souhaitez télécharger une image sur votre compte aconex, vous devez d'abord la connecter.",
      connect_with_procore: "Connectez-vous avec procore",
      connect_with_procore_text:
        "Pour le moment, vous n'êtes pas connecté à Procore, si vous souhaitez télécharger une image sur votre compte procore, vous devez d'abord la connecter.",
      connect_with_autodesk_text:
        "Pour le moment, vous n'êtes pas connecté à Autodesk, si vous souhaitez télécharger une image sur votre compte procore, vous devez d'abord la connecter.",
      upload_to_autodesk: "Téléchargez vos images sur Autodesk",
      voyage_control_description: "Connectez vos données à VoyageControl.",
      connect_to_voyage_control: "Connectez votre compte VoyageControl",
      voyage_control_success: "Connecté à VoyageControl avec succès",
      voyage_control_error: "Échec de la connexion à VoyageControl",
      reconnect_autodesk: "Reconnecter autodesk",
      reconnect_with_autodesk_viewer_text:
        "Si vous voulez voir votre model il faut nous donner accées au viewables",
      autodesk_model_change_success: "Model mise a jour avec succès",
      autodesk_model_change_error: "Échec de mise a jour avec autodesk",
      upload_success: "Image téléchargée avec succès",
    },
    user_basic_info_form: {
      missing_information: "Informations de compte manquantes",
      description:
        "Veuillez prendre un moment pour compléter les informations de compte manquantes",
      checkbox_label: "Ne plus afficher ce message",
    },
    pa: {
      title:
        "Utilisateurs actifs quotidiens, hebdomadaires et mensuels, 2021-2022",
    },
    auth: {
      login_failed: "Impossible de se connecter",
      signup_failed: "Échec de l'inscription",
      send_reset_password_failed:
        "Impossible d'envoyer la réinitialisation de mot de passe",
      reset_password_failed: "Impossible de réinitialiser le mot de passe",
    },
    video_wall: {
      camera_selection_title: "Sélectionnez les caméras",
      camera_selection_info:
        "Sélectionnez les caméras à afficher sur le mur vidéo",
      grid_configuration_title: "Configuration de la grille",
      grid_configuration_info_1:
        "Les valeurs de la grille sont calculées pour un format d'écran 16:9.",
      grid_configuration_info_2:
        "Veuillez redimensionner, glisser et déposer pour obtenir la mise en page souhaitée.",
      save_configuration_success: "Configuration sauvegardée avec succès",
      save_configuration_failed:
        "Échec de la sauvegarde de la configuration du mur vidéo, veuillez réessayer",
    },
    notifications: {
      title: "Notifications",
      no_notifications: "Aucune notification",
      mark_all_as_read: "Tout marquer comme lu",
      shared_camera_with_you: "a partagé une caméra avec vous",
      camera_went_offline:
        "La caméra <b>{cameraName}</b> est maintenant hors ligne",
      camera_came_online:
        "La caméra <b>{cameraName}</b> est de nouveau en ligne",
      camera_status_change_generic:
        "La caméra <b>{cameraName}</b> a changé de status et est desormais <b>{status}</b>",
      timelapsed_created:
        "Votre Time-Lapse pour la camera <b>{cameraName}</b> a été créé avec succès",
      timelapsed_failed:
        "Échec de la création de votre Time-Lapse pour la camera <b>{cameraName}</b>",
      drone_processing_done:
        "Un nouveau vol capturé le <b>{droneFlightDate}</b> pour <b>'{projectName}'</b> est maintenant disponible",
      processing_360_done:
        "Une nouvelle visite virtuelle à 360 degrés, capturée depuis le sol <b>{floor}</b> le <b>{date}</b> pour <b>{projectName}</b>, est maintenant disponible",
    },
    ingest: {
      drone: {
        header_text:
          "Drone View offre une représentation détaillée du chantier de construction et de ses environs",
        subheader_text1:
          "Localisez et accédez facilement à toutes les fonctionnalités d'Evercam grâce à la vue en direct de la caméra",
        subheader_text2:
          "Mesurez les distances, les hauteurs et les surfaces. Créez des étiquettes sur le modèle pour mettre en évidence des informations.",
        subheader_text3:
          "Suivez l'avancement sur le chantier et comparez différents vols dans l'espace 3D",
        create: "Téléchargez votre vol de drone",
      },
      bim: {
        header_text:
          "Explorez les modèles d'informations sur les bâtiments et les chronologies avec BIM par Evercam",
        subheader_text1:
          "Visualisation dynamique : Plongez dans des modèles 3D détaillés",
        subheader_text2:
          "Mesurez les distances, les hauteurs et les surfaces. Créez des étiquettes sur le modèle pour mettre en évidence des informations.",
        subheader_text3:
          "Phases de projet intégrées : Naviguez facilement à travers les chronologies",
        create: "Téléchargez votre BIM",
      },
      upload: {
        do_not_close_dashboard:
          "Vos fichiers sont en cours de téléchargement. Ne fermez pas le tableau de bord.",
        drone_title: "Téléchargement de drones",
        bim_title: "Téléchargement du BIM",
        files_processing: "Vos fichiers sont en cours de traitement.",
        create_upload_failed: "Échec de la création du téléchargement !",
      },
    },
    confirmation: "Confirmation",
    comments: {
      comments: "Commentaires",
      create_failed: "Échec de la création du nouveau commentaire",
      create_success: "Commentaire ajouté avec succès",
      delete: "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
      archive: "Êtes-vous sûr de vouloir archiver ce commentaire ?",
      delete_failed: "Échec de la suppression du commentaire",
      delete_success: "Commentaire supprimé avec succès",
      archive_success: "Commentaire archivé avec succès",
      archive_failed: "Échec de l'archivage du commentaire",
      unarchive_success: "Commentaire désarchivé avec succès",
      unarchive_failed: "Échec du désarchivage du commentaire",
      no_archived_comments: "Aucun commentaire archivé",
      no_active_comments: "Aucun commentaire actif",
      tabs: {
        active: "Actif",
        archived: "Archivé",
      },
    },
  },
  settings: {
    select_camera: "Sélectionnez une caméra",
    api_key_warning: `
    L'ID API et la clé sont destinés aux développeurs.
        Ne partagez pas cette information, cela donne l'accès à votre compte Evercam.`,
    api_documentation: "Documentation API disponible ici.",
  },
  generic_errors: {
    unique: "'{target}' a déjà été pris.",
    cast: "Valeur de champ invalide : '{target}'",
    required: "'{target}' est un champ obligatoire",
    exclusion: "",
    format: "",
    bad_argument: "Errors de validation",
    device_error: "Impossible d'envoyer la requête à un périphérique",
    unsupported_operation: "Opération non prise en charge",
    quota_exceeded: "Limite autorisée dépassée, réessayez plus tard.",
    decommissioned_camera: "Opération invalide sur une caméra désaffectée",
    unavailable_location: "La caméra n'a pas de (longitude/latitude) définie",
    nvr_missing: "La caméra n'est pas connectée à un NVR",
    nvr_unsupported_operation:
      "Le NVR ne prend pas en charge l'opération actuelle ou n'est pas encore implémenté",
    nvr_missing_rtsp: "Le port RTSP du NVR n'est pas exposé ou accessible",
    password_reset:
      "Vous devez réinitialiser votre mot de passe pour accéder à Evercam",
    invalid_credentials: "Nom d'utilisateur ou mot de passe incorrect",
    invalid_token: "Jeton invalide ou expiré",
    provider_auth_failure: "Échec de l'authentification auprès du fournisseur",
    provider_email_required:
      "L'e-mail est manquant ou n'est pas configuré dans le compte du fournisseur",
    generic: "Erreur interne du serveur 500",
    project_has_cameras:
      "Impossible de supprimer un projet avec des caméras associées",
    nvr_offline: "Le NVR est hors ligne.",
    unsupported_file_type: "Format de fichier non pris en charge",
    require_share_request:
      "Impossible de connecter l'utilisateur : demande de partage manquante",
    method_not_allowed: "La caméra ne prend pas en charge l'opération.",
    invalid_operation: "La caméra ne prend pas en charge l'opération",
    device_unsupported_operation:
      "Le dispositif ne prend pas en charge l'opération actuelle ou n'est pas encore implémenté",
  },
  copilot: {
    suggestions: {
      user_manual: "Comment puis-je partager une caméra dans Evercam ?",
      gate_report: "Quels véhicules sont arrivés sur place hier ?",
      site_activity: "Que s'est-il passé la semaine dernière?",
      clip: "Créer un clip pour les 30 dernières minutes",
    },
    labels: {
      projectExid: "Projet",
      cameraExid: "Camera",
      fromDate: "Date de début",
      toDate: "Date de fin",
    },
  },
}
